import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useGoBack } from "./hooks";
import { Dashboard as DBLayout, IdleWarning } from "./components";
import { Routes, Outlet, Route, Navigate } from "react-router-dom";
import {
  Dashboard,
  InstructorsPage,
  CoursePage,
  StudentPage,
  SettlementPage,
  TransactionPage,
  DeactivatedPage,
  FlaggedPage,
  AwaitingApprovalPage,
  AwaitingInstructorsPage,
  InstructorDetailsPage,
  StudentDetailPage,
  ApproveInstructorPage,
  CourseDetailPage,
  ReviewPage,
  CourseDetailPage2,
  ResetPassword,
  ForgotPassword,
  NotFound,
  Login,
  ManageAdmin,
  ProfileFormPage,
  CoursePricing,
  ProfileCard,
  Verification,
  DeactivatedInstructorsPage,
  DiscountTablePage,
  DiscountDetail,
  CreateDiscount,
} from "./pages";
import { MailingListPage } from "./pages/student/MailingListPage"

const AppLayout = () => {
  const [showWarning, setShowWarning] = useState(false);

  const handleOnIdle = () => {
    setShowWarning(true);
  };

  const handleOnAction = () => {
    setShowWarning(false);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30, //30 mins
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <DBLayout>
      <Outlet />
      {showWarning ? <IdleWarning show={showWarning} /> : null}
    </DBLayout>
  );
};

function App() {
  const { monitorPath } = useGoBack();

  useEffect(() => {
    monitorPath();
  }, [monitorPath]);

  return (
    <>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Dashboard />} />

          <Route path="dashboard" index element={<Dashboard />} />
          <Route path="discount-code" element={<DiscountTablePage />} />
          <Route path="create-discount-code" element={<CreateDiscount />} />

          <Route
            path="discount-code/:discountId"
            element={<DiscountDetail />}
          />
          <Route path="instructors" element={<InstructorsPage />} />
          <Route
            path="deactivatedInstructors"
            element={<DeactivatedInstructorsPage />}
          />
          <Route
            path="instructors/:instructorId"
            element={<InstructorDetailsPage />}
          />
          <Route
            path="pendingInstructors"
            element={<AwaitingInstructorsPage />}
          />
          <Route
            path="pendingInstructorsDetails/:id"
            element={<ApproveInstructorPage />}
          />
          <Route path="courses" element={<CoursePage />} />
          <Route
            path="reviewAssessment/:courseId/:moduleId/:moduleAssessmentId"
            element={<ReviewPage />}
          />
          <Route path="pendingCourses" element={<AwaitingApprovalPage />} />
          <Route
            path="pendingCourseDetail/:courseId"
            element={<CourseDetailPage />}
          />
          <Route
            path="courseDetail/:courseId"
            element={<CourseDetailPage2 />}
          />
          <Route path="activeStudents" element={<StudentPage />} />
          <Route path="students/:studentId" element={<StudentDetailPage />} />
          <Route path="deactivatedStudents" element={<DeactivatedPage />} />
          <Route path="mailing-list" element={<MailingListPage />} />

          <Route path="transactions" element={<TransactionPage />} />
          <Route path="settlements" element={<SettlementPage />} />
          <Route path="flagged" element={<FlaggedPage />} />
          <Route path="pricing" element={<CoursePricing />} />

          <Route path="profile" element={<ProfileCard />} />
          <Route path="editProfile" element={<ProfileFormPage />} />
          <Route path="manage" element={<ManageAdmin />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verification" element={<Verification />} />
        <Route path="/" element={<Navigate replace to="/dashboard" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
