import React, { useState } from "react";
import defaultLogo from "../../assets/images/unifyLogo.png";
import { NavLink } from "react-router-dom";
import { MenuOutlined, CloseOutlined,LogoutOutlined } from "@ant-design/icons";
import { MenuItem } from "./MenuItem";
import {useLogout} from "../../hooks";


const NavBar = () => {
    const appLogout = useLogout();
    const [active, setActive] = useState(false);
    // const [showDropdown1, setShowDropdown1] =useState(false);
    const [showDropdown2, setShowDropdown2] =useState(false);

  //   const toggle1 =()=>{
  //     setShowDropdown1(s => !s);
  //     setShowDropdown2(false);
  // }

  const toggle2 =()=>{
    setShowDropdown2(s => !s);
    // setShowDropdown1(false);
  }

    const showMenu = () => {
      setActive(!active);
    };

  return (
    <div className="absolute w-full h-[60px] text-white justify-between p-1 items-center bg-[#2C2C2C] border z-10">

     <NavLink to="/"><img src={defaultLogo} alt="Logo" className="ml-3 md:ml-[50px]" /></NavLink> 

      <div className="absolute right-6 lg:hidden top-4 scale-150">
        {!active ? (
          <MenuOutlined
            onClick={showMenu}
            className="scale-150 cursor-pointer"
          />
        ) : (
          <CloseOutlined
            onClick={showMenu}
            className="scale-150 cursor-pointer"
          />
        )}
      </div>
      <ul className="hidden lg:flex lg:justify-end lg:relative lg:top-[-55px] lg:left-[5px] xl:left-[-70px] lg:items-center lg:gap-4 xl:gap-8 p-4 ">
        <li>
          <NavLink

            to="/dashboard"
            className={({ isActive }) =>
              isActive
                ? "text-white  hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white "
                : "text-white hover:text-[#88062f]"          
              }

          >
            DashBoard
          </NavLink>
        </li>
        <li>
          <NavLink

            to="/activeStudents"
            className={({ isActive }) =>
              isActive
                ? "text-white hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white"
                : "text-white hover:text-[#88062f]"          

            }
          >
            Students
          </NavLink>
        </li>
        <li>
          <NavLink

            to="/instructors"
            className={({ isActive }) =>
              isActive
                ? "text-white hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white"
                : "text-white hover:text-[#88062f]"          

            }
          >
            Instructors
          </NavLink>
        </li>
        <li>
          <NavLink

            to="/transactions"
            className={({ isActive }) =>
              isActive
              ? "text-white hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white"
              : "text-white hover:text-[#88062f]"          
            }
          >
            Transactions
          </NavLink>
        </li>
        <li>
          <NavLink

            to="/courses"
            className={({ isActive }) =>
              isActive
                ? "text-white hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white"
                : "text-white hover:text-[#88062f]"          

            }
          >
            Courses
          </NavLink>
        </li>
        <li>
          <NavLink

            to="/flagged"
            className={({ isActive }) =>
              isActive
                ? "text-white hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white"
                : "text-white hover:text-[#88062f]"          

            }
          >
            Flagged/Reported
          </NavLink>
        </li>
        <li>
          <NavLink

            to="/manage"
            className={({ isActive }) =>
              isActive
                ? "text-white hover:bg-[#5F0C27] hover:rounded-md hover:p-2 hover:font-semibold hover:text-white"
                : "text-white hover:text-[#88062f]"          

            }
          >
            Manage Admin
          </NavLink>
        </li>

        {/* <li id="dropdownDefault" data-dropdown-toggle="dropdown" onClick={toggle1} className="text-white text-center inline-flex items-center" type="button">
          <BellOutlined style={{ fontSize: "24px", color: "#fff" }} />
          <span className="relative top-[-15px] left-[-5px] text-red-600 font-bold">2</span>
        </li> */}
        
           {/* DropDown Notifications */}
        {/* <div id="dropdown" className={`${showDropdown1 ? "absolute top-14" : "hidden"} z-10 w-72 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}>
        <div className="flex justify-between">
        <p className="text-gray-700 font-bold ml-4">My Notifications</p>
        <div className="text-end">
                <button onClick={toggle1} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-dropdown-toggle="dropdown">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
        </div>
        <div className="bg-gray-100 mb-2 hover:bg-gray-300">
          <p className="text-gray-800 ml-6">New Instructor Approval Request</p>
          <p className="text-gray-400 pb-2  text-xs ml-6 mt-[-15px]">12:00am 24/07/2022</p>
        </div>
        <div className="bg-gray-100 mb-2 hover:bg-gray-300">
          <p className="text-gray-800 ml-6">New Instructor Approval Request</p>
          <p className="text-gray-400 pb-2  text-xs ml-6 mt-[-15px]">12:00am 24/07/2022</p>
        </div>
       </div> */}

        <li id="dropdownDefault" data-dropdown-toggle="dropdown2" onClick={toggle2} className="text-white text-center inline-flex items-center " type="button">
          Admin
          <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
            </li> 
  
            {/* Dropdown menu  */}
    <div id="dropdown2" className={`${showDropdown2 ? "absolute top-14" : "hidden"} z-10 w-32 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}>
    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
      <li className="hover:m-1 hover:bg-[#B61046] hover:rounded-md">
      <NavLink
    to="/profile"
    className="text-[#5F0C27] inline-block ml-6 py-2 px-4 hover:text-white  "> 
      My Profile
      </NavLink> <img className=" mt-[-28px] ml-2 w-6 h-6 rounded-full"  src="https://images.unsplash.com/photo-1520813792240-56fc4a3765a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
      alt="profile"/></li>

<li className="hover:m-1 hover:bg-[#B61046] hover:rounded-md">
    <button
    onClick={appLogout}
    className="text-[#5F0C27] inline-block ml-6 py-2 px-4 hover:text-white">
      Logout
      </button><LogoutOutlined style={{marginLeft:"-87px"}}/></li>
    </ul>
</div>
   

      </ul>
        

      <MenuItem showMenu={showMenu} active={active} />
    </div>
  );
}

export { NavBar }