import ExcelJS from 'exceljs'
import { axiosApiInstanceGet } from './axios'
import { API_URL_V1 } from './constants'

/**
 * set the properties of a workbook
 * @param {ExcelJS.Workbook} workbook the workbook object
 * @param {Date} startDate the start date of the transaction report
 * @param {Date} endDate the end date of the transaction report
 */
export function setWorkbookInitialProperties(workbook, startDate, endDate) {
  workbook.creator = 'Fusion Intelligence'
  workbook.lastModifiedBy = 'Fusion Intelligence'
  workbook.modified = new Date()
  workbook.company = 'Fusion Intelligence'
  workbook.description = `Transaction Reports from ${startDate.toDateString()} to ${endDate.toDateString()}`
}

/**
 * set the columns of the transactions worksheet
 * @param {ExcelJS.Worksheet} sheet the worksheet object
 */
export function setUpWorkSheetColumns(sheet) {
  sheet.columns = [
    { header: 'S/N', key: 'sn', width: 10 },
    { header: 'Course Name', key: 'courseName', width: 30 },
    { header: 'Student Name', key: 'studentName', width: 30 },
    { header: 'Price', key: 'price', width: 10 },
    { header: 'Status', key: 'status', width: 10 },
    { header: 'Transaction Ref', key: 'transactionRef', width: 32 },
    { header: 'Date Completed', key: 'dateCompleted', width: 25 },
  ]
}

/**
 * fills the sheet / add rows with the provided data
 * @param {ExcelJS.Worksheet} sheet
 * @param {*} data

 */
export function addWorkSheetRows(sheet, data) {
  for (let i = 0; i < data.length; i++) {
    sheet.addRow({
      sn: i + 1,
      courseName: data[i].courseName,
      studentName: data[i].userSummary.name,
      price: data[i].amount,
      status: data[i].transactionStatus,
      transactionRef: data[i].referenceNumber,
      dateCompleted: data[i].dateCompleted,
    })
  }
}

/**
 * prints the workbook and generates a .xlsx file
 * @param {ExcelJS.Workbook} workbook
 */
export async function printWorkbook(workbook) {
  console.log(workbook)
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer])
  const fileUrl = URL.createObjectURL(blob)

  let anchorTag = document.createElement('a')
  anchorTag.setAttribute('download', `${workbook.description}.xlsx`)
  anchorTag.setAttribute('href', fileUrl)
  document.body.append(anchorTag)
  anchorTag.click()
}

const generateExcelWorkBook = async (startDate, endDate) => {
  let allTransactions = []

  try {
    const response = await axiosApiInstanceGet(
      `${API_URL_V1}/Transactions/list-all-transactions?page=${1}&perPage=${400000}&dateFrom=${startDate}&dateTo=${endDate}`,
      true,
    )
    allTransactions = response.data?.data.pagedList
  } catch (error) {
    console.error(error)
    return false
  }

  const workbook = new ExcelJS.Workbook()

  setWorkbookInitialProperties(workbook, new Date(startDate), new Date(endDate))

  const sheet = workbook.addWorksheet('Transactions', {
    pageSetup: { orientation: 'landscape' },
  })

  setUpWorkSheetColumns(sheet)

  addWorkSheetRows(sheet, allTransactions)

  printWorkbook(workbook)
}

export default generateExcelWorkBook
